import { AxiosError } from "axios";
import truncate from "lodash/truncate";
import flatten from "lodash/flatten";
import has from "lodash/has";
import sortBy from "lodash/sortBy";
import moment from "moment";
import _ from "lodash";
import isFuture from "date-fns/isFuture";
import differenceInYears from "date-fns/differenceInYears";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInDays from "date-fns/differenceInDays";

import { User } from "shared/context/types/company";
import { RefetchOptions } from "shared/components/common-table/types/index";
import { agreementsCustomTooltip, agreementsDefaultTooltip } from "shared/data/agreementsTooltip";

import { GeographiesResultType } from "modules/agreements/overview/types/index";
import { StatusResultType } from "modules/tasks/statuses/types";
import { SelectedTemplateType } from "modules/agreements/overview/types";
import { ParagraphSetType } from "modules/policies/overview/types";

import {
  TokenKey,
  RefreshTokenKey,
  IN_OPERATOR,
  TASK_VIEW,
  TOGGLE_VIEW,
  DEFAULT_PAGE_SIZE,
} from "./constant";
import { CustomFlowbiteTheme } from "flowbite-react";
import { ProfileResponse } from "modules/settingsV2/my-account/types";

export const getToken = () => {
  try {
    return window.localStorage.getItem(TokenKey);
  } catch (error) {
    return null;
  }
};

export const setToken = (token: string) => {
  try {
    return window.localStorage.setItem(TokenKey, token);
  } catch (error) {
    return null;
  }
};

export const removeToken = () => {
  try {
    return window.localStorage.removeItem(TokenKey);
  } catch (error) {
    return null;
  }
};

export const getRefreshToken = () => {
  try {
    return window.localStorage.getItem(RefreshTokenKey);
  } catch (error) {
    return null;
  }
};

export const setRefreshToken = (token: string) => {
  try {
    return window.localStorage.setItem(RefreshTokenKey, token);
  } catch (error) {
    return null;
  }
};

export const removeRefreshToken = () => {
  try {
    return window.localStorage.removeItem(RefreshTokenKey);
  } catch (error) {
    return null;
  }
};

export const getServerErrors = (error: AxiosError | null) => {
  if (
    error?.response &&
    error.response.data &&
    error.response.status !== 500 &&
    typeof error.response.data !== "string"
  ) {
    const response = error.response.data || {};

    const arrays = Object.entries(response).map((value) => value[1]);

    return flatten(arrays);
  }

  return [truncate(error?.message)];
};

export const hasError = (field: string, error: AxiosError) => {
  return has(error?.response?.data, field);
};
export const getDate = (date: Date): string => {
  const convertedDate = new Date(date).toDateString().split(" ").splice(1).join(" ");
  return [
    convertedDate?.split(" ")[0],
    [convertedDate?.split(" ")[1], convertedDate.split(" ")[2]]?.join(","),
  ].join(" ");
};
export const convertDate = (date: Date | string): string => {
  return moment(date).format("YYYY-MM-DD");
};

export const getDateDifference = (date1: Date | string, date2: Date | string) => {
  const now = isFuture(new Date(date1)) ? new Date(date2) : new Date(date1);
  const future = isFuture(new Date(date1)) ? new Date(date1) : new Date(date2);

  const years = differenceInYears(future, now);

  if (years > 0 && years !== 0) {
    if (years === 1) {
      return isFuture(new Date(date1)) ? `${years} year` + " left" : years + " year ago";
    } else {
      return isFuture(new Date(date1)) ? `${years} years` + " left" : years + " years ago";
    }
  }

  const months = differenceInMonths(future, now);

  if (months > 0 && months !== 0) {
    if (months === 1) {
      return isFuture(new Date(date1)) ? `${months} month` + " left" : months + " month ago";
    } else {
      return isFuture(new Date(date1)) ? `${months} months` + " left" : months + " months ago";
    }
  }

  let days = differenceInDays(future, now);

  if (days > 0 && days !== 0) {
    days = isFuture(new Date(date1)) ? days + 1 : days;
    if (days === 1) {
      return isFuture(new Date(date1)) ? `${days} day` + " left" : days + " day ago";
    } else {
      return isFuture(new Date(date1)) ? `${days} days` + " left" : days + " days ago";
    }
  } else {
    if (days === 0) {
      return isFuture(new Date(date1)) ? `1 day` + " left" : days + " ago";
    }
  }
};
export const getStatusName = (status: StatusResultType[], statusId: number) => {
  const data = status?.find((item) => item?.id === statusId);
  return data?.name;
};

export const initialDataForList = {
  results: [],
  next: null,
  previous: null,
  page_limit: DEFAULT_PAGE_SIZE,
  count: 0,
};

export const getId = (name: string) => {
  try {
    return window.localStorage.getItem(name);
  } catch (error) {
    return null;
  }
};

export const setId = (name: string, id: string) => {
  try {
    return window.localStorage.setItem(name, id);
  } catch (error) {
    return null;
  }
};

export const setItemToLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    return null;
  }
};

export const getItemFromLocalStorage = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
  } catch (error) {
    return null;
  }
};

export const removeItemFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    return null;
  }
};

export const getElementOffSetTop = (element: HTMLDivElement | null) => {
  if (element) {
    return element.getBoundingClientRect().top;
  }
  return 0;
};

export const isNotificationSupported = () => {
  return "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;
};

export const getFileNameFromS3URL = (url: string) => {
  return new URL(url).pathname.split("/").reverse()[0];
};

const randomAvatarColor = [
  "bg-classicRose",
  "bg-palelavender",
  "bg-palePink",
  "bg-slate-200",
  "bg-lavenderblue",
  "bg-blueChalk",
  "bg-azureishWhite",
  "bg-aliceBlue",
  "bg-blond",
  "bg-lavender",
  "bg-antiqueWhite",
  "bg-amour",
  "bg-azureishWhite",
  "bg-iceberg",
  "bg-ceramic",
];
const randomAvatarFontColor = [
  "text-dogwoodRose",
  "text-lovelyPurple",
  "text-lavared",
  "text-slate-600",
  "text-crayolasblue",
  "text-purpleHeart",
  "text-tropicalRainForest",
  "text-bondiBlue",
  "text-dirtyOrange",
  "text-egyptianBlue",
  "text-glantsOrange",
  "text-raspberryPink",
  "text-greenhaze",
  "text-bondiBlue",
  "text-cornflower",
];

export const colorClassFromName = (name: string, avatarColors = randomAvatarColor) => {
  let hash = 0;
  const len = name.length;
  for (let i = 0; i < len; i++) {
    hash = (hash << 5) - hash + name.charCodeAt(i);
    hash |= 0;
  }
  const index = Math.abs(hash) % avatarColors.length;
  return {
    backgroundColor: avatarColors[index],
    color: randomAvatarFontColor[index],
  };
};

export const convertMultiSelectTemplate = (
  data: GeographiesResultType[]
): SelectedTemplateType[] => {
  const dataStoreTemplate = [] as SelectedTemplateType[];
  data?.forEach((item) => dataStoreTemplate.push({ label: item.name, value: item.id }));
  return dataStoreTemplate;
};

export const shouldActive = (label: string) => {
  const active = label?.replace(" ", "")?.includes("No Access".replace(" ", ""));
  return active;
};

export const toDollar = (amount: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  return formatter.format(amount);
};

export const formatNumber = (amount: number, fractionDigit = 0) => {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: fractionDigit,
  });
  return formatter.format(amount);
};

export const getAgreementTooltips = (id: number) => {
  return id?.toString() === process.env.REACT_APP_HOIST_COMPANY_ID
    ? agreementsCustomTooltip
    : agreementsDefaultTooltip;
};

export const getOrderedParagraphSet = (
  paragraphs: ParagraphSetType[] | undefined
): ParagraphSetType[] => {
  return sortBy(paragraphs || [], "display_order");
};

export const groupRefetchOptions = (options: RefetchOptions[] | []): RefetchOptions[] | [] => {
  return [
    ..._(options?.filter((item) => item.field.includes("__" + IN_OPERATOR)))
      .groupBy((x) => x.field)
      .map((value, key) => ({
        field: key,
        value: [value?.map((item) => item.value)]?.join(", "),
        type: value[0]?.type,
      }))
      .value(),
    ...options.filter((item) => !item.field.includes("__" + IN_OPERATOR)),
  ];
};

export const getHostFromUrl = (url: string) => {
  return new URL(url).hostname;
};

export const getGrowthPercent = (previousRevenue: number, revenue: number) =>
  previousRevenue !== 0
    ? parseInt(formatNumber(((revenue - previousRevenue) * 100) / (previousRevenue || 1), 2)) || 0
    : 0;

export const getGCSPercentage = (data: number) => parseInt(formatNumber((data || 0) * 100, 2));

export const getUserAbbreviation = (user: User | null | undefined) => {
  if (user) {
    return getUsername(user)?.charAt(0)?.toUpperCase() || "";
  }
};

export const getUsername = (
  user: User | ProfileResponse | undefined | null,
  defaultValue = "N/A"
) => {
  return truncate(user?.name || user?.email || "") || defaultValue;
};

export const getFullUserName = (user: User | undefined | null, defaultValue = "N/A") => {
  return user?.name || user?.email || "" || defaultValue;
};

export const getBreadCrumbLinkForTask = (viewType: string) => {
  switch (viewType) {
    case TASK_VIEW:
      return `/tasks/overview`;
    case TOGGLE_VIEW:
      return `/tasks/gridview`;
    default:
      return `/tasks/overview`;
  }
};

export const customTheme: CustomFlowbiteTheme = {
  accordion: {
    root: {
      base: "divide-y divide-gray-200 border-gray-200 dark:divide-jet",
      flush: {
        off: "rounded-lg border",
        on: "border-b",
      },
    },
    content: {
      base: "py-4 px-4 last:rounded-b-lg dark:bg-mirage first:rounded-t-lg dark:!border-t-jet",
    },
    title: {
      arrow: {
        base: "h-6 w-6 shrink-0",
        open: {
          off: "",
          on: "rotate-180",
        },
      },
      base: "flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg p-4 text-left font-medium text-gray-500 dark:text-gray-400 text-sm",
      flush: {
        off: "hover:bg-gray-100 dark:hover:bg-raisinblack",
        on: "bg-transparent dark:bg-transparent",
      },
      heading: "",
      open: {
        off: "dark:text-[#8e8e8f]",
        on: "text-gray-900 bg-gray-100 dark:bg-raisinblack dark:text-white",
      },
    },
  },

  tab: {
    tablist: {
      styles: {
        default:
          "flex-wrap md:border-b border-gray-200 dark:border-gray-700 maxMd:flex-col maxMd:px-4",
        underline: "flex-wrap -mb-px border-b border-gray-200 dark:border-gray-700",
        pills: "flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400 space-x-2",
        fullWidth:
          "w-full text-sm font-medium divide-x divide-gray-200 shadow grid grid-flow-col dark:divide-gray-700 dark:text-gray-400 rounded-none",
      },
      tabitem: {
        base: "",
        styles: {
          default: {
            base: "text-sm py-3.5 px-5 border border-b-0 md:border-l-0 border-brightgray dark:border-thunders hover:bg-ghostwhite",
            active: {
              on: " text-mirage dark:bg-balticsea dark:hover:text-white dark:text-white dark:border-t-white border-t-2 border-t-mirage",
              off: "text-gray-500 hover:text-gray-600 dark:text-greychateau dark:hover:bg-balticsea  dark:hover:text-gray-300",
            },
          },
        },
      },
    },
  },
  modal: {
    root: {
      base: "fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 dark:bg-[#39393bcc]",
    },
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner:
        "relative rounded-lg bg-white shadow dark:bg-darkjunglegreens flex flex-col max-h-[90vh]",
    },
    header: {
      base: "flex items-start justify-between rounded-t dark:border-gray-600 border-b p-4 md:p-5",
      close: {
        base: "ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white !cursor-pointer",
        icon: "w-5 h-5 !cursor-pointer modal_svg",
      },
    },
  },
};
