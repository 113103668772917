import React, { ReactNode } from "react";

export type ChatListProps = {
  children: ReactNode;
};

export default function ChatList({ children }: ChatListProps) {
  return (
    <div data-testid="chat-list">
      <ul className="chat_list">{children}</ul>
    </div>
  );
}
