import Copilot from "./components/copilot";
import useCopilotViaWs from "./hooks/useCopilotViaWs";

function CopilotContainer() {
  const { copilotMessages: messages, sendCopilotMessage: sendMessage, isReady } = useCopilotViaWs();

  return <Copilot disabled={!isReady} messages={messages} onMessageSend={sendMessage} />;
}

export default CopilotContainer;
