import React, { useState, useCallback, useMemo } from "react";
import get from "lodash/get";
import posthog from "posthog-js";

import { useMutation } from "@tanstack/react-query";
import { Alert, Modal, Tooltip } from "flowbite-react";
import clsx from "clsx";

import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import { AssistantForm } from ".";
import { AssistantCard } from "./types";
import { AssistantCardList } from ".";
import { useTeam } from "../../store/settings";
import { useCompany } from "../../context/CompanyProvider";
import { askQuestionWithSources } from "./api";
import Spinner from "../spinner/Spinner";
import { AssistantResponse } from ".";
import ToggleSwitch from "../toggle-switch";
import { AssistantFormData } from "./AssistantForm";
import Icon from "../icon/Icon";

const assistantCardList: AssistantCard[] = [
  {
    id: 1,
    title: "How will the sale of Credit Suisse affect the regulatory climate in Europe?",
    description:
      "The sale of Credit Suisse could potentially affect the regulatory climate in Europe by making money harder to come by due to the combination of banking crises in the U.S. and Europe. This situation may lead to increased scrutiny and tighter regulations for financial institutions in the region. However, it is important to note that the European banking sector is generally considered to be resilient enough to withstand the fragility caused by the Credit Suisse situation. As a result, while there may be some short-term impacts on the regulatory climate, the overall stability of the European financial system should remain intact.",
  },
  {
    id: 2,
    title:
      "What are the key requirements for firms operating under the EU Digital Operational Resilience Act (DORA)?",
    description:
      "The EU Digital Operational Resilience Act (DORA) has five key requirements for firms operating under its regulations. These requirements include: 1) ICT Risk Management, which involves identifying, assessing, and mitigating risks related to information and communication technology; 2) ICT-related Incident Management, which focuses on the detection, response, and recovery from incidents affecting ICT systems; 3) Digital Operational Resilience Testing, which requires firms to conduct regular testing to ensure the resilience of their ICT systems; 4) ICT Third Party Risk Management, which involves managing risks associated with third-party service providers; and 5) Information Sharing Arrangements, which promote the sharing of information related to cyber threats and incidents among firms and authorities [1][2][3].",
  },
  {
    id: 3,
    title: "How do firms manage the KYC of intermediaries?",
    description:
      "Firms manage the Know Your Customer (KYC) process for intermediaries through a similar approach called Know Your Intermediary (KYI) [1]. KYI focuses on entities operating on behalf of the company itself, rather than independent parties [1]. The scope and depth of the investigation for KYI may vary depending on the nature of the intermediary and the associated risks [1]. Firms may use specialized services and tools, such as those provided by SWIFT, to help streamline the KYC and KYI processes [2]. By implementing a robust KYI process, firms can better manage their compliance with financial regulations and mitigate potential risks associated with intermediaries [1].",
  },
  {
    id: 4,
    title: "What are the minimum requirements on remote authentication for payment providers.",
    description:
      "The minimum requirements on remote authentication for payment providers are outlined in the Payment Card Industry Data Security Standard (PCI DSS) requirement 8.5.1 [1]. This requirement states that service providers with remote access to customer facilities for activities such as supporting Point of Sale (POS) systems must use unique authentication credentials for each customer [1]. Additionally, these credentials should not be shared among multiple customers or used for other purposes [1]. The Payment Card Industry Security Standards Council, which manages the PCI DSS, was formed by major card companies like American Express, Discover Financial Services, JCB International, MasterCard, and Visa Inc. [2]. The goal of the council is to ensure the ongoing evolution of the PCI DSS and maintain a secure environment for payment processing [2].",
  },
];

function AssistantModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}) {
  const [eurlex, setEurlex] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const { currentTeam } = useTeam();
  const { currentCompany } = useCompany();
  const { documentBodyRef } = useDocumentBodyRef();

  // Use the first team id if the current team is all team coz we need a team id to ask a question
  const teamId = useMemo(() => {
    return !currentTeam.is_all_team ? currentTeam.id : get(currentCompany, ["teams", 0, "id"], 0);
  }, [currentTeam, currentCompany]);

  const askQuestionMutation = useMutation(({ question: userQuestion }: { question: string }) => {
    return askQuestionWithSources(userQuestion, teamId, eurlex);
  });

  const submitQuestion = useCallback((data: AssistantFormData) => {
    posthog.capture("answersQuestionSubmited");
    setQuestion(data.question);
    askQuestionMutation.mutate(data);
  }, []);

  const submitSelectedCard = useCallback((card: AssistantCard) => {
    setQuestion(card.title);
    askQuestionMutation.mutate({ question: card.title });
  }, []);

  const resetQuestion = useCallback(() => {
    setQuestion("");
    askQuestionMutation.reset();
  }, []);

  return (
    <>
      <Modal
        root={documentBodyRef}
        className="grand-assistant-modal"
        show={isOpen}
        size="lg"
        dismissible={true}
        onClose={() => setIsOpen(false)}
      >
        <Modal.Header className="p-4 md:p-6 items-center w-full dark:!border-thunders">
          <div className="flex flex-row align-middle justify-between w-full pr-4">
            <h3 className="!w-auto text-mirage dark:text-white">Grand Answer</h3>
            <div className="maxMd:hidden">
              <ToggleSwitch
                checked={eurlex}
                handleChange={(e) => setEurlex(e.target.checked)}
                leftLabel="Only Eurlex"
                testId="eurlex-toggle"
                name="grand_assistant_modal"
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          className={clsx(
            { "pt-0": askQuestionMutation.isIdle },
            "maxMd:!px-4 maxMd:overflow-x-hidden"
          )}
        >
          {askQuestionMutation.isIdle ? (
            <>
              <AssistantForm onSubmit={submitQuestion} />
              <AssistantCardList cards={assistantCardList} onSelect={submitSelectedCard} />
            </>
          ) : (
            <>
              <div className="md:hidden -mt-6">
                <AssistantForm onSubmit={submitQuestion} />
              </div>
              <h2 data-testid="question" className="text-base dark:text-white mb-3 font-bold">
                {question}
              </h2>

              {askQuestionMutation.isLoading ? <Spinner /> : null}

              {askQuestionMutation.isSuccess ? (
                <AssistantResponse sourceResponse={askQuestionMutation.data} />
              ) : null}

              {askQuestionMutation.isError ? (
                <Alert color="warning">
                  Currently we are experiencing high traffic, please come back later.
                </Alert>
              ) : null}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="py-4 dark:!border-thunders justify-between maxMd:px-4 maxMd:flex-wrap gap-2">
          <div className="flex gap-2 items-center">
            <Tooltip
              content="Grand Answer is intended to provide guidance and assistance with regulatory questions&#8228; However&#x2c; please be aware that the information provided is not legal advice and should not be relied upon as such&#8228; Always consult with a licensed legal professional for specific legal advice related to your situation&#8228;"
              className="custom_tooltip long-tooltip-content"
            >
              <Icon type="exclamation-circle" fill={false} size="icon-xs" />
            </Tooltip>
            <h4 className="text-sm text-aurometalsaurus font-medium dark:text-greychateau">
              This is not legal advice
            </h4>
          </div>
          <button
            className="btn_secondary ml-auto maxMd:hidden"
            type="button"
            onClick={resetQuestion}
            disabled={askQuestionMutation.isLoading}
          >
            Ask another question
          </button>
          <div className="md:hidden block maxMd:!ml-0">
            <ToggleSwitch
              checked={eurlex}
              handleChange={(e) => setEurlex(e.target.checked)}
              leftLabel="Only Eurlex"
              testId="eurlex-toggle"
              name="grand_assistant_modal"
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AssistantModal;
