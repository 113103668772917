import React, { useCallback, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import useScreen from "shared/hooks/useScreen";
import { useSideMenu } from "shared/store/rightSideBar";
import { IS_SIDEBAR_TOGGLE, MOBILE_DEVICE } from "shared/helpers/constant";
import { getItemFromLocalStorage, getUsername } from "shared/helpers/util";
import { useSidebar } from "shared/storeV2/sidebar";
import { defaultSideBarItems } from "shared/helpers/sidebar";
import CompanySwitcher from "shared/componentsV2/company-dropdown";
import { useCompany } from "shared/context/CompanyProvider";
import Avatar from "shared/componentsV2/avatar";
import { useHome } from "shared/store/home";

import styles from "assets/css/sidebarV2.module.css";

import { SideBarItems } from "../../components/sidebar/types";
import SidebarComponentModule from "./SidebarComponentModule";
import Icon from "../../components/icon/Icon";
import { QUESTIONNAIRE_MODULE } from "shared/helpers/ability";
import checkEnvVar from "shared/helpers/checkEnvVar";
import { useSettings } from "shared/store/settings";
import Settings from "modules/settings/Settings";

const SidebarComponent = () => {
  const deviceType = useScreen();
  const setIsSettingsModalOpen = useSettings((state) => state.setIsModalOpen);
  const profile = useHome((state) => state.profile);

  const { pathname } = useLocation();

  const { currentCompany, currentUserRole } = useCompany();

  const [sideBarItems, setSidebarItems] = useState<Array<SideBarItems>>([]);
  const setIsSideMenuShown = useSideMenu((state) => state.setIsSideMenuShown);

  const [isToggle, isVisible, setIsToggle, setIsVisible] = useSidebar((state) => [
    state.isToggle,
    state.isVisible,
    state.setIsToggle,
    state.setIsVisible,
    state.sideBarItems,
    state.setSidebarItems,
  ]);

  useEffect(() => {
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
      setIsToggle(getItemFromLocalStorage(IS_SIDEBAR_TOGGLE));
    }
  }, [deviceType]);

  const onClick = useCallback(
    (index: number) => {
      setSidebarItems((prev) => {
        const newItems = [...prev];
        newItems.forEach((item: SideBarItems, itemIndex: number) => {
          if (itemIndex === index) {
            return;
          }
          item.isOpen = false;
        });
        newItems[index].isOpen = !prev[index].isOpen;
        return newItems;
      });
      setIsSideMenuShown(false);
    },
    [sideBarItems]
  );

  const closeAllSubmenu = () => {
    setSidebarItems((prev) => {
      const newItems = [...prev];
      newItems.forEach((item: SideBarItems) => {
        item.isOpen = false;
      });
      return newItems;
    });
  };

  const setOpenDropnDownOnRefresh = () => {
    setSidebarItems((prev) => {
      const newItems = prev.length === 0 ? defaultSideBarItems(currentCompany?.id) : [...prev];
      newItems.forEach((navLink: SideBarItems) => {
        navLink.items?.find((submenu) => {
          if (pathname?.startsWith(submenu.path)) navLink.isOpen = true;
        });
      });

      return currentUserRole === "GUEST"
        ? newItems.filter((x) => x.subject === QUESTIONNAIRE_MODULE)
        : newItems;
    });
  };

  useEffect(() => {
    closeAllSubmenu();
    if (!isToggle) {
      setOpenDropnDownOnRefresh();
    }
  }, [isToggle, currentUserRole, currentCompany]);

  if (!isVisible && deviceType === MOBILE_DEVICE) {
    return null;
  }

  return (
    <section
      className={clsx(
        styles.sidebar,
        "dark:bg-darkjunglegreenss dark:border-thunders print-ignore",
        {
          [styles.sidebarFullWidth]: !isToggle,
          [styles.sidebarToggle]: isToggle,
        }
      )}
    >
      <div className="flex flex-col gap-6">
        <CompanySwitcher />
        <SidebarComponentModule onClick={onClick} sideBarItems={sideBarItems} />
      </div>
      <div className="flex flex-col gap-2 py-[10px] px-2">
        <div className="flex flex-row gap-2 items-center">
          <Icon type="setting-icon" size="icon-sm" fill={true} className="table_icon" />
          {checkEnvVar("REACT_APP_ENABLE_NEW_SETTING_UI") ? (
            <Link
              className="text-sm text-brightgrey font-medium dark:text-gray_700"
              to={"/settings/my-account"}
            >
              Settings
            </Link>
          ) : (
            <>
              <button
                className="text-sm text-brightgrey font-medium dark:text-gray_700"
                onClick={() => setIsSettingsModalOpen(true)}
              >
                Settings
              </button>
              <Settings />
            </>
          )}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <Avatar
            name={getUsername(profile)}
            avatarFor="user"
            avatarType="rounded"
            size="smd"
            url={profile?.profile_picture}
            className="cursor-default"
          />
          <Tooltip
            content={profile?.name || profile?.email}
            className="metadata_tooltip"
            arrow={false}
            placement="top-start"
          >
            <span className="block w-44 text-sm text-brightgrey font-medium dark:text-gray_700 whitespace-nowrap overflow-hidden text-ellipsis">
              {profile?.name || profile?.email}
            </span>
          </Tooltip>
        </div>
      </div>
    </section>
  );
};

export default SidebarComponent;
