import React, { MouseEventHandler } from "react";

import { AssistantCard } from "./types";

export type AssistantCardItemProps = {
  card: AssistantCard;
  onClick?: MouseEventHandler;
};

function AssistantCardItem({ card, onClick: handleClick }: AssistantCardItemProps) {
  return (
    <div
      data-testid="card"
      className="border border-brightgray dark:border-thunders p-4 rounded-2xl cursor-pointer"
      onClick={handleClick}
    >
      <h5 className="text-xs font-inter-medium mb-2 tracking-tight text-mirage dark:text-white">
        {card.title}
      </h5>
      <p className="font-medium text-xs text-aurometalsaurus dark:text-greychateau grand-assistant-modal__disc">
        {card.description}
      </p>
    </div>
  );
}

export default AssistantCardItem;
