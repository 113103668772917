import React, { FC, MouseEvent, MouseEventHandler, useCallback } from "react";
import clsx from "clsx";

import Icon from "shared/components/icon/Icon";

export type HeaderProps = {
  title: string;
  onCollapse?: MouseEventHandler;
};

/**
 * Header component got for CopilotLayout.
 * Will be used to display the title of the copilot window.
 */
const Header: FC<HeaderProps> = ({ title, onCollapse: handleCollapse }: HeaderProps) => {
  const handleDownArrowClicked = useCallback((event: MouseEvent) => {
    if (handleCollapse) {
      handleCollapse(event);
    }
  }, []);

  return (
    <div
      className={clsx(
        "grow-0",
        "flex",
        "flex-row",
        "items-center",
        "align-center",
        "bg-mirage",
        "rounded-t-lg",
        "text-white",
        "h-12",
        "pt-2",
        "pb-2",
        "pr-6",
        "pl-6",
        "w-full"
      )}
    >
      <div className={clsx("flex", "grow")}>{title}</div>
      <div className="down_icon">
        <span onClick={handleDownArrowClicked} data-testid="downarrow">
          <Icon type="down" fill={false} size="icon-smd" />
        </span>
      </div>
    </div>
  );
};

export default Header;
