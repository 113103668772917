import { useState } from "react";
import clsx from "clsx";

import CopilotChat from "../copilot-chat";
import { ChatMessage } from "../../types";

export type CopilotProps = {
  disabled?: boolean;
  messages: ChatMessage[];
  onMessageSend: (message: string) => void;
};

function Copilot({ disabled, messages, onMessageSend: sendMessage }: CopilotProps) {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <div
      className={clsx(
        "flex flex-col fixed bottom-6 right-18 z-50 maxMd:pointer-events-none assistant_card",
        {
          "md:w-full": opened,
          "md:max-w-[400px]": opened,
        }
      )}
    >
      <div
        data-testid="copilot-chat-container"
        className={clsx({
          hidden: !opened,
        })}
      >
        <CopilotChat
          messages={messages}
          disabled={disabled}
          onMessageSend={sendMessage}
          onCollapse={() => setOpened(false)}
        />
      </div>
      <div className={clsx("flex", "flex-row-reverse", "mt-4")}>
        <button
          data-testid="copilot-collapse-button"
          type="button"
          className="btn_primary maxMd:!pointer-events-auto"
          onClick={(e) => {
            e.preventDefault();
            setOpened(!opened);
          }}
        >
          Assistant
        </button>
      </div>
    </div>
  );
}

export default Copilot;
