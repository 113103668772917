import React, { FC, ReactNode } from "react";
import clsx from "clsx";

export type FooterProps = {
  children: ReactNode;
};

const Footer: FC<FooterProps> = ({ children }: FooterProps) => {
  return (
    <div
      className={clsx(
        "rounded-b-lg",
        "bg-ghostwhite",
        "border-t",
        "border-gainsboro",
        "p-3",
        "dark:border-iridium",
        "dark:bg-thunders",
        "dark:text-greychateau",
        "footer_icon"
      )}
    >
      {children}
    </div>
  );
};

export default Footer;
