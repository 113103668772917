import { CopilotChatMessage } from "../types";

function isCopilotChatMessage(message: unknown): message is CopilotChatMessage {
  return (
    typeof message === "object" &&
    message !== null &&
    "id" in message &&
    "text" in message &&
    "type" in message &&
    "user" in message &&
    "created_at" in message &&
    "updated_at" in message
  );
}

export default isCopilotChatMessage;
