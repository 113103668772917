import React from "react";

import Icon from "../icon/Icon";

import { AssistantSourceResponse } from "./types";

export type AssistantResponseProps = {
  sourceResponse: AssistantSourceResponse;
};

function AssistantResponse({ sourceResponse }: AssistantResponseProps) {
  return (
    <>
      <p data-testid="answer" className="text-sm text-aurometalsaurus dark:text-greychateau pb-6">
        {sourceResponse.answer}
      </p>
      {sourceResponse.sources.length > 0 ? (
        <>
          <div
            data-testid="source-list"
            className="flex flex-wrap gap-2 border-t border-brightgray dark:border-thunders pt-6"
          >
            {sourceResponse.sources.map((source, index) => (
              <a
                className="bg-antiflashwhite dark:bg-iridium dark:text-white rounded text-xs px-2 py-1 text-aurometalsaurus font-inter-semibold break-all"
                key={source}
                href={source}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="inline-flex gap-[6px] text-santagrey dark:text-stormdust font-inter-medium">
                  {index + 1} <span>|</span>
                  <Icon type="simple-link" fill={true} size="icon-xs" className="w-3.5 h-3.5" />
                </span>{" "}
                {source}
              </a>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
}

export default AssistantResponse;
