import React, { useState } from "react";

import { createPortal } from "react-dom";

import Icon from "shared/components/icon/Icon";
import { AssistantModal } from "shared/components/assistant-modal";

import HeaderItem from "./HeaderItem";
import { useWorkspaceModal } from "../../../store/trial";
import { useCompany } from "../../../context/CompanyProvider";

const AssistantItem = () => {
  const [isAssistantModalOpen, setIsAssistantModalOpen] = useState(false);
  const setWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);
  const { currentCompany } = useCompany();

  return (
    <>
      <HeaderItem
        testId="assistant_comp"
        tooltip="Grand Answer"
        className="header_action_assistant"
        onClick={() =>
          currentCompany?.trial_expired
            ? setWorkspaceModalOpen(true)
            : setIsAssistantModalOpen(true)
        }
      >
        <Icon type="question" fill={true} size="icon-smd" className="questionicon_dark" />
      </HeaderItem>
      {isAssistantModalOpen
        ? createPortal(
            <AssistantModal isOpen={isAssistantModalOpen} setIsOpen={setIsAssistantModalOpen} />,
            document.body
          )
        : null}
    </>
  );
};

export default AssistantItem;
