import { useState } from "react";

import useWebSocket, { ReadyState } from "react-use-websocket";
import posthog from "posthog-js";

import { useTeam } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";
import { useUser } from "shared/context/UserProvider";
import { getToken } from "shared/helpers/util";

import { ChatMessage, CopilotChatMessage, CopilotHookInterface } from "../types";
import { getSeed, isCopilotChatMessage, isTemporaryMessage } from "../helpers";

function getWebsocketUrl(userId: number) {
  return `${process.env.REACT_APP_WEBSOCKET_URL}/${userId}/`;
}

export default function useCopilotViaWs(): CopilotHookInterface {
  const { user } = useUser();
  const { currentTeam } = useTeam();
  const { currentCompany } = useCompany();
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  /**
   * Set a temporary message that will be replaced by the copilot message when received.
   * @param message - users message
   * @param seed - seed for the message
   */
  function setTemporaryMessage(message: string, seed: string) {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        text: message,
        seed: seed,
        type: "user",
        temporary: true,
      },
    ]);
  }

  /**
   * Remove all temporary messages Assume that temporary message are not been delivered or handled
   * by the backend.
   */
  function removeTemporaryMessages() {
    setMessages((prevMessages) => prevMessages.filter((message) => !isTemporaryMessage(message)));
  }

  /**
   * Sets the copilot message. If it is message with type `copilot` then append it.
   * If it is message with type `user` then find the temporary message with the same seed
   * and replace it with the copilot message. If temporary message with same seed does not exist
   * then append the copilot message.
   * @param message - copilot message received from the backend
   */
  function setCopilotMessage(message: CopilotChatMessage) {
    if (message.type === "copilot") {
      setMessages((prevMessages) => [...prevMessages, message]);
    } else if (message.type === "user") {
      setMessages((prevMessages) => {
        const index = prevMessages.findIndex((prevMessage) => {
          return isTemporaryMessage(prevMessage) && prevMessage.seed === message.seed;
        });
        if (index === -1) {
          return [...prevMessages, message];
        } else {
          const newMessages = [...prevMessages];
          newMessages[index] = message;
          return newMessages;
        }
      });
    }
  }

  const { sendJsonMessage, readyState } = useWebSocket(getWebsocketUrl(user.id), {
    queryParams: {
      access_token: getToken() || "",
    },
    onMessage: (message) => {
      try {
        posthog.capture("assistantAsked");
        const messageData = JSON.parse(message.data);
        if (isCopilotChatMessage(messageData)) {
          setCopilotMessage(messageData);
        } else {
          console.warn("Received message that is not a copilot message", messageData);
        }
      } catch (e) {
        console.error("Error parsing message", e);
        return;
      }
    },
    onError: () => {
      removeTemporaryMessages();
    },
    onClose: () => {
      removeTemporaryMessages();
    },
    shouldReconnect: () => {
      return true;
    },
    reconnectAttempts: 10,
  });

  return {
    copilotMessages: messages,
    sendCopilotMessage: (message: string) => {
      const seed = getSeed();
      sendJsonMessage({
        message,
        team: currentTeam.id,
        company: currentCompany.id,
        seed: seed,
      });
      setTemporaryMessage(message, seed);
    },
    isReady: readyState === ReadyState.OPEN,
  };
}
