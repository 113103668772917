import React, { useState, useRef } from "react";

import Icon from "shared/components/icon/Icon";

export type ChatFormProps = {
  disabled?: boolean;
  onMessageSend?: (message: string) => void;
};

function ChatForm({ onMessageSend: fireSendMessage, disabled }: ChatFormProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const [message, setMessage] = useState<string>("");

  const sendMessage = () => {
    if (message && fireSendMessage && !disabled) {
      fireSendMessage(message);
      setMessage("");
    }
  };

  return (
    <form
      data-testid="chat-form"
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        sendMessage();
      }}
    >
      <textarea
        data-testid="chat-input"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Talk to your data. Ask questions about your company data e.g., Documents, Policies, Agreements, Notes."
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
          }
        }}
      />
      <div className="flex flex-row-reverse">
        <button disabled={disabled} data-testid="chat-button" type={"submit"}>
          <Icon type="send" fill={false} size="icon-xs" />
        </button>
      </div>
    </form>
  );
}

export default ChatForm;
