import { useCompany } from "shared/context/CompanyProvider";
import { useUser } from "shared/context/UserProvider";
import { isCompanyOwner } from "../../../shared/context/helpers";

/**
 * Checks is copilot is visible for user and company.
 * It's visible only if user is owner of company and company is enterprise.
 */
export default function useCopilotEnabled() {
  const { user } = useUser();
  const { currentCompany } = useCompany();

  return (
    currentCompany && currentCompany.is_enterprise && user && isCompanyOwner(currentCompany, user)
  );
}
