import {
  AGREEMENTS,
  AGREEMENT_REMINDERS,
  APPEARANCE,
  BILLING,
  COMPANY,
  INSTITUTION_TYPES,
  MEMBERS,
  MY_ACCOUNT,
  NOTIFICATION,
  RISKS,
  RISKS_REMINDERS,
  RISKS_PRESET,
  UPGRADE,
  GROUPS,
} from "shared/helpers/constant";
import {
  AppearanceIcon,
  BelllIcon,
  CreditCardIcon,
  MemberIcon,
  SettingIcon,
  GroupsIcon,
  UpgradeIcon,
  MyAccountIcon,
  RiskSettingIcon,
  InstitutionTypeIcon,
} from "shared/components/icons/Icons";
import { useCompany } from "shared/context/CompanyProvider";
import checkEnvVar from "shared/helpers/checkEnvVar";

import { SettingTypes } from "../types";
import MyAccount from "../my-account";
import { Notification } from "../notification/Notification";
import Appearance from "../appearance";
import Members from "../members";
import Groups from "../groups";
import Upgrade from "../upgrade";
import Billing from "../billing";
import Preset from "../risk/preset";
import WorkspaceSettings from "../workspace-settings";

const useGetSidebarItems = () => {
  const { currentCompany } = useCompany();

  const accountSettings: Array<SettingTypes> = [
    {
      title: "My Account",
      icon: <MyAccountIcon />,
      slug: MY_ACCOUNT,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: "my-account",
      element: <MyAccount />,
    },
    {
      title: "Notification Settings",
      icon: <BelllIcon />,
      slug: NOTIFICATION,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: "notification",
      element: <Notification />,
    },
    {
      title: "Appearance",
      icon: <AppearanceIcon />,
      slug: APPEARANCE,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: "appearance",
      element: <Appearance />,
    },
  ];

  const companySettings: Array<SettingTypes> = [
    {
      title: "Workspace Settings",
      icon: <SettingIcon />,
      slug: COMPANY,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: `${currentCompany.id}/workspace-settings`,
      element: <WorkspaceSettings />,
    },
    {
      title: "Members",
      icon: <MemberIcon />,
      slug: MEMBERS,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: `${currentCompany.id}/members`,
      element: <Members />,
    },
    {
      title: "Groups",
      icon: <GroupsIcon />,
      slug: GROUPS,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: `${currentCompany.id}/groups`,
      element: <Groups />,
    },
    {
      title: "Agreements",
      icon: <RiskSettingIcon />,
      slug: AGREEMENTS,
      stripeIncluded: false,
      isVisible: () => checkEnvVar("REACT_APP_AGREEMENTS_REMINDERS_ENABLE"),
      children: [
        {
          title: "Reminders",
          slug: AGREEMENT_REMINDERS,
          stripeIncluded: false,
          isVisible: () => checkEnvVar("REACT_APP_AGREEMENTS_REMINDERS_ENABLE"),
          redirectTo: `${currentCompany.id}/agreement/reminders`,
          element: <div className="dark:text-white">{AGREEMENT_REMINDERS}</div>,
        },
      ],
      element: <div className="dark:text-white">{AGREEMENTS}</div>,
    },
    {
      title: "Risk Settings",
      icon: <RiskSettingIcon />,
      slug: RISKS,
      stripeIncluded: false,
      isVisible: () => true,
      children: [
        {
          title: "Risk Reminders",
          slug: RISKS_REMINDERS,
          stripeIncluded: false,
          isVisible: () => checkEnvVar("REACT_APP_RISKS_REMINDERS_ENABLE"),
          redirectTo: `${currentCompany.id}/risk/reminders`,
          element: <div className="dark:text-white">{RISKS_REMINDERS}</div>,
        },

        {
          title: "Preset",
          slug: RISKS_PRESET,
          stripeIncluded: false,
          isVisible: () => true,
          redirectTo: `${currentCompany.id}/risk/preset`,
          element: <Preset />,
        },
      ],
      element: <div>{RISKS}</div>,
    },
    {
      title: "Institution Types",
      icon: <InstitutionTypeIcon />,
      slug: INSTITUTION_TYPES,
      stripeIncluded: false,
      isVisible: () => checkEnvVar("REACT_APP_INSTITUTION_TYPES_ENABLE"),
      redirectTo: "institutation_types",
      element: <div className="dark:text-white">{INSTITUTION_TYPES}</div>,
    },
    {
      title: "Upgrade",
      icon: <UpgradeIcon />,
      slug: UPGRADE,
      stripeIncluded: true,
      isVisible: () => !currentCompany.is_enterprise && checkEnvVar("REACT_APP_STRIPE_ENABLE"),
      redirectTo: "upgrade",
      element: <Upgrade />,
    },
    {
      title: "Billing",
      icon: <CreditCardIcon />,
      slug: BILLING,
      stripeIncluded: true,
      isVisible: () => !currentCompany.is_enterprise && checkEnvVar("REACT_APP_STRIPE_ENABLE"),
      redirectTo: "billing",
      element: <Billing />,
    },
  ];
  return { companySettings, accountSettings };
};

export default useGetSidebarItems;
