import { TemporaryUserMessage } from "../types";

export default function isTemporaryMessage(message: unknown): message is TemporaryUserMessage {
  return (
    typeof message === "object" &&
    message !== null &&
    "text" in message &&
    "seed" in message &&
    "type" in message &&
    "temporary" in message
  );
}
