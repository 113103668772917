import { useEffect, useRef } from "react";

import { ChatMessage as ChatMessageType } from "../../types";
import CopilotLayout from "../copilot-layout";
import ChatList from "../chat-list";
import ChatMessage from "../chat-message";
import ChatForm from "../chat-form";

function getKeyForMessage(message: ChatMessageType) {
  if ("id" in message) {
    return message.id;
  }

  return message.seed;
}

export type CopilotChatProps = {
  messages: ChatMessageType[];
  disabled?: boolean;
  onMessageSend: (message: string) => void;
  onCollapse: () => void;
};

function CopilotChat({
  messages,
  disabled,
  onCollapse: handleCollapse,
  onMessageSend: sendMessage,
}: CopilotChatProps) {
  const mainLayoutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mainLayoutRef.current) {
      mainLayoutRef.current.scrollBy({
        top: mainLayoutRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages.length]);

  return (
    <CopilotLayout>
      <CopilotLayout.Header title="Grand Copilot" onCollapse={handleCollapse} />
      <CopilotLayout.Main ref={mainLayoutRef}>
        <ChatList>
          {messages.map((message) => (
            <ChatMessage key={getKeyForMessage(message)} message={message} />
          ))}
        </ChatList>
      </CopilotLayout.Main>
      <CopilotLayout.Footer>
        <ChatForm onMessageSend={sendMessage} disabled={disabled} />
      </CopilotLayout.Footer>
    </CopilotLayout>
  );
}

export default CopilotChat;
