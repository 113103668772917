import React, { Ref, FormEvent, useEffect, useRef, useCallback } from "react";

import { TextInput } from "flowbite-react";
import get from "lodash/get";

import { ReactComponent as SearchIcon } from "assets/images/icon/search.svg";

export type AssistantFormData = {
  question: string;
};

export type AssistantFormProps = {
  onSubmit: ({ question }: AssistantFormData) => void;
};

/**
 * Gets and trims string value from input ref
 */
function getValueFromInputRef(ref: Ref<HTMLInputElement>): string {
  const value = get(ref, ["current", "value"], "");
  return value.toString().trim();
}

function AssistantForm({ onSubmit: handleSubmit }: AssistantFormProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const submitHandler = useCallback((event: FormEvent) => {
    event.preventDefault();
    const question = getValueFromInputRef(inputRef);

    if (question !== "") {
      handleSubmit({ question });
    }
  }, []);

  return (
    <form
      id="assistant-form"
      data-testid="assistant-form"
      className="flex flex-col"
      onSubmit={submitHandler}
    >
      <div className="border-b dark:border-thunders mb-6 -mx-6">
        <TextInput
          ref={inputRef}
          id="question"
          data-testid="question"
          placeholder="How can we help you?"
          sizing="lg"
          className="search-icon-dark"
          icon={SearchIcon}
        />
      </div>
    </form>
  );
}

export default AssistantForm;
