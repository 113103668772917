import clsx from "clsx";
import React from "react";

const DisplayLabel = ({
  label,
  testId,
  labelClass,
}: {
  label: string;
  labelClass?: string;
  testId?: string;
}) => {
  return (
    <span
      className={clsx(labelClass, "text-base font-inter-medium text-mirage dark:text-white")}
      data-testid={testId}
    >
      {label}
    </span>
  );
};

export const ToggleSwitch = ({
  toggleIconClass,
  leftLabel,
  rightLabel,
  name,
  testId,
  checked,
  className,
  handleChange,
}: {
  toggleIconClass?: string;
  leftLabel?: string;
  rightLabel?: string;
  name: string;
  testId?: string;
  checked: boolean;
  className?: string;
  handleChange: (data: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="relative flex items-start">
      {leftLabel ? <DisplayLabel label={leftLabel} testId="left_label" labelClass="mr-4" /> : null}
      <label className="inline-flex relative items-center cursor-pointer">
        <input
          onChange={handleChange}
          checked={checked}
          type="checkbox"
          data-testid={testId}
          name={name}
          className="sr-only peer"
        />
        {toggleIconClass ? (
          <div data-testid="custom_toggle" className={toggleIconClass} />
        ) : (
          <div
            className={clsx(
              "bg-gray-300 w-11 h-6 peer-focus:outline-none rounded-full peer dark:bg- peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 dark:peer-checked:bg-iridium peer-checked:bg-mirage",
              className
            )}
          />
        )}
      </label>
      {rightLabel ? (
        <DisplayLabel label={rightLabel} testId="right_label" labelClass="ml-2" />
      ) : null}
    </div>
  );
};
