import { useInfiniteQuery } from "@tanstack/react-query";

import getPageFromNextPage from "shared/helpers/getPageFromNextPage";
import { CompanyId, TeamParameter } from "shared/types";

import { getStatus } from "modules/tasks/statuses/api";

export default function useGetStatus(
  queryKey: string,
  team: TeamParameter,
  companyId: CompanyId,
  searchString?: string
) {
  return useInfiniteQuery(
    [queryKey, companyId, team?.team, searchString],
    ({ pageParam = 1 }) => {
      const requestParams = {
        page: pageParam,
        name__icontains: searchString,
      };

      return getStatus(searchString ? requestParams : { page: pageParam }, {
        team: team,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.next != null) {
          return getPageFromNextPage(lastPage.data.next);
        }
      },
      enabled: !!team?.team,
      refetchOnWindowFocus: false,
    }
  );
}
