import React from "react";

import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import { useCompany } from "shared/context/CompanyProvider";

import style from "assets/css/settings.module.css";

import useGetSidebarItems from "../hooks/useGetSidebarItems";
import SidebarDropdown from "./SidebarDropdown";

export default function SettingSidebar() {
  const location = useLocation();
  const { currentCompany, currentUserRole: role } = useCompany();
  const { accountSettings, companySettings } = useGetSidebarItems();

  return (
    <>
      <div className={style.setting_sidebar}>
        <section className={style.setting_card}>
          <ul>
            <h3 className={style.setting_title}>Account</h3>
            {accountSettings
              .filter((item) => item.isVisible())
              .map((item, index) => {
                if (item.children && item.children?.length > 0) {
                  return <SidebarDropdown sidebarItem={item} key={index} />;
                }

                return (
                  <Link
                    key={item.slug}
                    to={`/settings/${item.redirectTo}`}
                    className={clsx(style.sidebar_item, {
                      [style.sidebar_item_active]:
                        item.redirectTo &&
                        location.pathname.endsWith(`settings/${item.redirectTo}`),
                    })}
                  >
                    {item.icon ? <span className={style.sidebar_icon}>{item.icon}</span> : null}
                    <span>{item.title}</span>
                  </Link>
                );
              })}
          </ul>
        </section>
        {role !== "GUEST" && (
          <div className={clsx(style.setting_card, style.company_setting_card)}>
            <ul>
              <h3 className={style.setting_title}>Workspace ({currentCompany.name || "N/A"})</h3>
              {companySettings
                .filter((item) => item.isVisible())
                .map((item) => {
                  if (item.children && item.children?.length > 0) {
                    return <SidebarDropdown sidebarItem={item} />;
                  }

                  return (
                    <Link
                      key={item.slug}
                      to={`/settings/${item.redirectTo}`}
                      className={clsx(style.sidebar_item, {
                        [style.sidebar_item_active]:
                          item.redirectTo &&
                          location.pathname.endsWith(`settings/${item.redirectTo}`),
                      })}
                    >
                      {item.icon ? <span className={style.sidebar_icon}>{item.icon}</span> : null}
                      <span>{item.title}</span>
                    </Link>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
