import React, { Suspense } from "react";

import clsx from "clsx";
import { Route, Routes } from "react-router-dom";
import get from "lodash/get";

import NotFound from "shared/components/not-found";
import SidebarComponent from "shared/componentsV2/sidebarV2/SidebarComponent";
import Spinner from "shared/components/spinner/Spinner";
import { useCompany } from "shared/context/CompanyProvider";
import { useSidebar } from "shared/storeV2/sidebar";

import CanAccess from "./components/CanAccess";

const Vendors = React.lazy(() => import("modules/vendors"));
const Questionnaire = React.lazy(() => import("modules/questionnaires"));
const Assistant = React.lazy(() => import("modules/assistant"));
const Risks = React.lazy(() => import("modules/risksV2"));
const DashboardsReports = React.lazy(() => import("modules/dashboards-reports"));

export default function ModuleRoutes() {
  const { currentCompany, currentUserRole } = useCompany();
  const isGuest = currentUserRole === "GUEST";

  const isToggle = useSidebar((state) => state.isToggle);

  return (
    <>
      <div className="flex relative bg-ghostwhite overflow-hidden h-screen print:overflow-visible">
        <SidebarComponent />
        <div
          className={clsx(
            {
              "main-content-toggle": isToggle,
              "main-content-full": !isToggle,
            },
            "main-content-style"
          )}
        >
          <div className="min-h-full h-full">
            <Suspense
              fallback={
                <div className="loader">
                  <Spinner />
                </div>
              }
            >
              <Routes>
                <Route path={`/:companyId`}>
                  {!isGuest && (
                    <>
                      <Route
                        path={`vendors/*`}
                        element={
                          <CanAccess
                            isVisible={
                              currentCompany?.modules?.thirdparty?.enabled &&
                              currentCompany?.modules?.thirdparty?.vendors_enabled
                            }
                          >
                            <Vendors />
                          </CanAccess>
                        }
                      />
                      <Route path={`risks/*`} element={<Risks />} />
                      <Route
                        path={`reports/*`}
                        element={
                          <CanAccess
                            isVisible={get(currentCompany?.modules, "report.enabled", false)}
                          >
                            <DashboardsReports />
                          </CanAccess>
                        }
                      />
                      <Route
                        path="assistant/*"
                        element={
                          <CanAccess
                            isVisible={get(currentCompany?.modules, "assistant.enabled", false)}
                          >
                            <Assistant />
                          </CanAccess>
                        }
                      />
                    </>
                  )}
                  <Route
                    path={`questionnaire/*`}
                    element={
                      <CanAccess isVisible={true}>
                        <Questionnaire />
                      </CanAccess>
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
