import React from "react";

import { useCompany } from "shared/context/CompanyProvider";
import Spinner from "shared/components/spinner/Spinner";

import AddTeam from "./add-team/AddTeam";
import TeamsList from "./team-list/TeamsList";

const TeamsSettings = () => {
  const { isLoading, currentCompany, refetch } = useCompany();

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <AddTeam />
          <TeamsList teamList={currentCompany?.teams} refetch={refetch} />
        </>
      )}
    </>
  );
};

export default TeamsSettings;
