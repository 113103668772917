import React, { useCallback, useMemo, useState } from "react";

import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";

import {
  ActionsType,
  FiltersProp,
  RefetchOptions,
  SearchFields,
} from "shared/components/common-table/types";
import {
  AFTER_OPERATOR,
  BEFORE_OPERATOR,
  DATETIME_FILTER,
  DATE_FILTER,
  ICONTAINS_OPERATOR,
  IN_OPERATOR,
  PAGINATED_FILTER,
  TEXT_FILTER,
} from "shared/helpers/constant";
import { useTeam } from "shared/store/settings";
import { useCompany } from "shared/context/CompanyProvider";
import { useTask } from "shared/store/tasks";
import { GetTaskResultType, TagsObjectType } from "shared/types";
import getQueryParamsFromRefetchOptions from "shared/helpers/getQueryParamsFromRefetchOptions";
import { getGetTaskResultData } from "shared/helpers/getGetTaskResultData";
import useTagsList from "shared/hooks/useTagsList";
import { convertDate, getUsername } from "shared/helpers/util";
import { usePaginationSearch } from "shared/store/commonTable";
import { useWorkspaceModal } from "shared/store/trial";
import GridCardTags from "shared/components/grid-card/GridCardTags";
import { TASK_STATUS_TAG_CLASSES } from "shared/components/constants";
import convertMemberIdToUserId from "shared/helpers/convertMemberIdToUserId";

import { ReactComponent as TrashIcon } from "assets/images/icons/trash.svg";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";

import { getTasks } from "../api/getTasks";
import useGetStatus from "./useGetStatus";
import { CommonTableV2ColumnProps } from "shared/typesV2";
import Icon from "shared/components/icon/Icon";
import { TeamResponse } from "modules/settings/teams/types";

const useTaskCommonTable = ({
  onEdit: edit,
  teamId,
}: { onEdit?: (task: GetTaskResultType) => void; teamId?: number } = {}) => {
  const { currentCompany, usersList } = useCompany();
  const { paginationSearchData } = usePaginationSearch();

  const { currentTeam: _currentTeam, teams } = useTeam();

  const currentTeam = teamId
    ? (teams.find((team) => team.id === teamId) as TeamResponse)
    : _currentTeam;

  const {
    data: tagsResponse,
    isLoading: isLoadingTags,
    hasNextPage: hasTagsNextPage,
    isFetchingNextPage: isFetchingTagsNextPage,
    fetchNextPage: fetchTagsNextPage,
    isFetching: isTagsFetching,
  } = useTagsList(
    currentTeam?.id,
    currentTeam.company,
    currentTeam.is_all_team,
    paginationSearchData?.task?.tags
  );

  const setTrialExpired = useWorkspaceModal((state) => state.setIsModalOpen);
  const [filterOptions, setFilterOptions] = useState<RefetchOptions[]>([]);

  const permission = {
    team: currentTeam?.id,
    company: currentTeam.company,
    isAllTeam: currentTeam.is_all_team,
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState<GetTaskResultType[]>([]);
  const [statusId, setStatusId] = useState<number>(0);

  const navigate = useNavigate();

  const setTasks = useTask((state) => state.setTasks);
  const {
    data: statusResponse,
    hasNextPage: hasStatusNextPage,
    isFetchingNextPage: isFetchingStatusNextPage,
    fetchNextPage: fetchStatusNextPage,
    isLoading: isStatusLoading,
    isFetching: isStatusFetching,
  } = useGetStatus("getStatus", permission, currentCompany?.id, paginationSearchData?.task?.status);

  const filters: FiltersProp[] = useMemo(
    () => [
      {
        label: "Status",
        field: "status",
        values: statusResponse?.pages?.flatMap((item) =>
          item?.data?.results?.map((status: { id: number; name: string }) => ({
            key: status?.id,
            value: status?.name,
          }))
        ),
        type: PAGINATED_FILTER,
        operator: IN_OPERATOR,
        single_option: false,
        isLoading: isStatusLoading,
        isFetchingNextPage: isFetchingStatusNextPage,
        hasNextPage: hasStatusNextPage,
        fetchNextPage: fetchStatusNextPage,
        isFetchingData: isStatusFetching,
        responseDataDetails: {
          endpoint: "tasks/status/",
          params: {
            team: currentTeam?.is_all_team ? "all" : currentTeam?.id,
            company: currentTeam.company,
          },
        },
      },
      {
        label: "Name",
        field: "name",
        type: TEXT_FILTER,
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
      {
        label: "Description",
        field: "description",
        type: TEXT_FILTER,
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
      {
        label: "Due Date",
        field: "due_date",
        type: DATE_FILTER,
        operators: [AFTER_OPERATOR, BEFORE_OPERATOR],
        single_option: true,
      },
      {
        label: "Tags",
        field: "tags",
        values: tagsResponse?.pages?.flatMap((item) =>
          item?.data?.results?.map((tagRes: { id: number; name: string }) => ({
            key: tagRes?.id,
            value: tagRes?.name,
          }))
        ),
        type: PAGINATED_FILTER,
        operator: IN_OPERATOR,
        single_option: false,
        isLoading: isLoadingTags,
        hasNextPage: hasTagsNextPage,
        fetchNextPage: fetchTagsNextPage,
        isFetchingData: isTagsFetching,
        isFetchingNextPage: isFetchingTagsNextPage,
        responseDataDetails: {
          endpoint: "core/tags/",
          params: {
            team: currentTeam?.is_all_team ? "all" : currentTeam?.id,
            company: currentTeam.company,
          },
        },
      },
      {
        label: "Created At",
        field: "created_at",
        type: DATETIME_FILTER,
        operators: [AFTER_OPERATOR, BEFORE_OPERATOR],
        single_option: true,
      },
      {
        label: "Updated At",
        field: "updated_at",
        type: DATETIME_FILTER,
        operators: [AFTER_OPERATOR, BEFORE_OPERATOR],
        single_option: true,
      },
    ],
    [statusResponse, tagsResponse]
  );

  const columns = useMemo<ColumnDef<GetTaskResultType, unknown>[]>(
    () => [
      {
        header: "Task Name",
        accessorKey: "name",
      },
      {
        header: "Status",
        accessorFn: (row: GetTaskResultType) => {
          return row?.status?.name ? row?.status?.name : "N/A";
        },
        accessorKey: "status",
      },
      {
        header: "Tags",
        accessorFn: (row: GetTaskResultType) => {
          return row?.tags ? row?.tags[0] : "N/A";
        },
        accessorKey: "tags",
        cell: (info) => {
          const task = info.row.original;
          const tags = task?.tags_object?.slice(0, 3).map((tag: TagsObjectType) => tag.name) || [];
          return (
            <div className="-mt-4">
              <GridCardTags
                tags={tags}
                className={clsx(TASK_STATUS_TAG_CLASSES, "overflow-hidden !block text-ellipsis")}
              />
              {task?.tags_object?.length > 3 ? (
                <div className="text-xs mt-2 text-bondiBlue">{`+${
                  task?.tags_object?.length - 3
                } More`}</div>
              ) : null}
            </div>
          );
        },
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        accessorFn: (row: GetTaskResultType) => {
          return convertDate(row?.created_at);
        },
      },
      {
        header: "Updated At",
        accessorKey: "updated_at",
        accessorFn: (row: GetTaskResultType) => {
          return convertDate(row?.updated_at);
        },
      },
      {
        header: "Owner",
        accessorKey: "owner",
        accessorFn: (row: GetTaskResultType) => getUsername(row?.owner_object),
      },
      {
        header: "Priority",
        accessorKey: "priority",
        accessorFn: (row: GetTaskResultType) => {
          return row?.priority || "N/A";
        },
      },
      {
        header: "Due Date",
        accessorKey: "due_date",
        accessorFn: (row: GetTaskResultType) => {
          return row?.due_date || "N/A";
        },
      },
    ],
    []
  );

  const columnsV2 = useMemo<CommonTableV2ColumnProps<never>[]>(() => {
    return columns.map((column) => ({
      type: "text",
      Component: ({ row }) => {
        if (column.cell) {
          return (
            column as {
              cell: (props: unknown) => JSX.Element;
            }
          ).cell({ row: { original: row } });
        }

        if (
          (
            column as {
              accessorFn?: (row: unknown) => string;
            }
          )?.accessorFn
        ) {
          return (
            <span className="flex items-center gap-2 min-w-[16rem]">
              {(
                column as {
                  accessorFn?: (row: unknown) => string;
                }
              )?.accessorFn?.(row)}
            </span>
          );
        }

        return (
          <span className="flex items-center gap-2 min-w-[16rem]">
            {
              row[
                (
                  column as {
                    accessorKey: string;
                  }
                ).accessorKey
              ]
            }
          </span>
        );
      },
      sortKey: (
        column as {
          accessorKey: string;
        }
      ).accessorKey,
      Label: () => (
        <div className="flex gap-2 items-center">
          <Icon type="name" fill={true} size="icon-xs" className="table_circle_icon" />
          <span>
            {
              (
                column as {
                  header: string;
                }
              ).header
            }
          </span>
        </div>
      ),
      meta: {
        icon: <Icon type="name" fill={true} size="icon-xs" className="theme_icon column-icon" />,
        columnTitle: (
          column as {
            header: string;
          }
        ).header,
        isLocked: false,
        responseKey: "name",
      },
    }));
  }, [columns]);

  const {
    data: tasks,
    isLoading: taskLoading,
    isFetching: taskFetching,
    refetch: refetchTasksData,
  } = useQuery(
    ["getTasks", filterOptions, currentCompany?.id, currentTeam?.id],
    () =>
      getTasks(
        getQueryParamsFromRefetchOptions(
          convertMemberIdToUserId({ usersList, options: filterOptions })
        ),
        {
          team: permission,
        }
      ),
    {
      onSuccess: (res) => {
        setTasks(res.data);
      },
      enabled: !!currentTeam,
    }
  );

  const refetchTasks = useCallback((options: RefetchOptions[]) => {
    setFilterOptions(options);
  }, []);

  const deleteTask = (data: GetTaskResultType[]) => {
    setToBeDeleted([...data]);
    setIsDeleteModalOpen(true);
  };

  const editTask = (data: GetTaskResultType[]) => {
    if (data?.length) {
      if (currentCompany?.trial_expired) {
        setTrialExpired(true);
        return;
      }
      if (edit) {
        edit(data[0]);
      } else {
        navigate(`/tasks/${data[0].id}/edit`);
      }
    }
  };

  const actions: ActionsType<GetTaskResultType>[] = useMemo(
    () => [
      {
        key: "Edit",
        icon: <EditIcon />,
        action: (data) => editTask(getGetTaskResultData(data)),
      },
      {
        key: "Delete",
        icon: <TrashIcon />,
        action: (data) => deleteTask(getGetTaskResultData(data)),
      },
    ],
    []
  );

  const searchFields = useMemo<SearchFields[]>(
    () => [
      {
        label: "Name",
        value: "name",
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
      {
        label: "Description",
        value: "description",
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
    ],
    []
  );

  const statuses = useMemo(() => {
    return statusResponse?.pages?.flatMap((item) => item?.data?.results || []);
  }, [statusResponse]);

  return {
    statuses: statuses,
    statusId,
    setStatusId,
    isLoading: isStatusFetching || taskLoading || taskFetching,
    columns,
    columnsV2,
    filters,
    actions,
    searchFields,
    isModalOpen,
    setIsModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    toBeDeleted,
    refetchTasksData,
    refetchTasks,
    tasks: tasks?.data,
  };
};

export default useTaskCommonTable;
