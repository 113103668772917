import clsx from "clsx";
import { useEffect } from "react";
import { useDarkMode } from "usehooks-ts";

import { IS_SIDEBAR_TOGGLE, MOBILE_DEVICE } from "shared/helpers/constant";
import { getItemFromLocalStorage, setItemToLocalStorage } from "shared/helpers/util";
import useScreen from "shared/hooks/useScreen";
import { useTeam } from "shared/store/settings";
import { useSidebar } from "shared/store/sidebar";

import styles from "assets/css/header.module.css";
import logo from "assets/images/logo.svg";
import logoWhite from "assets/images/logowhite.svg";
import { ReactComponent as HamburgerMenuIcon } from "assets/images/task/menu.svg";

import checkEnvVar from "../../helpers/checkEnvVar";
import HeaderMenu from "./HeaderMenu";
import AssistantItem from "./items/AssistantItem";
import DocumentationItem from "./items/DocumentationItem";
import NotificationItem from "./items/NotificationItem";
import SearchItem from "./items/SearchItem";
import SettingsAndMembersItem from "./items/SettingsAndMembersItem";
import SwitchTeamPopup from "./items/SwitchTeamPopup";
import { useLocation } from "react-router-dom";

export default function Header() {
  const deviceType = useScreen();
  const location = useLocation();

  const hideTeamSwitch = ["/assistant/", "/workspace/", "/tasks/"].some((path) =>
    location.pathname.startsWith(path)
  );

  const [isToggle, isVisible, setIsToggle, setIsVisible] = useSidebar((state) => [
    state.isToggle,
    state.isVisible,
    state.setIsToggle,
    state.setIsVisible,
  ]);

  const currentTeam = useTeam((state) => state.currentTeam);

  const { isDarkMode } = useDarkMode();

  const onToggleClick = () => {
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(!isVisible);
      return;
    }
    setIsToggle(!isToggle);
    setItemToLocalStorage(IS_SIDEBAR_TOGGLE, JSON.stringify(!isToggle));
  };

  useEffect(() => {
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
      setIsToggle(getItemFromLocalStorage(IS_SIDEBAR_TOGGLE));
    }
  }, [deviceType]);

  return (
    <>
      <section
        className={clsx(styles.header, "dark:bg-darkjunglegreenss dark:border-thunders")}
        data-testid="header_comp"
      >
        {/* Left Header Items */}
        <div className="flex items-center">
          {/* Toggle Button */}
          <button data-testid="header_toggle" onClick={onToggleClick}>
            <HamburgerMenuIcon className="subscriptions_right" />
          </button>

          {/* Logo */}
          <div className={styles.logo}>
            <img src={isDarkMode ? logoWhite : logo} alt="logo" className="w-8 h-8" />
          </div>

          {/* Module Based Header */}
          <div
            data-testid="header_menu"
            className={clsx(
              styles.articleSubscriptions,
              { "md:ml-[11rem]": !isToggle },
              "ml-6 maxLg:hidden"
            )}
          >
            <HeaderMenu />
          </div>
        </div>

        {/* Right Header Items */}
        <div className="flex relative items-center">
          {/* switch tem dropdown */}
          {!hideTeamSwitch && <SwitchTeamPopup />}

          {/* Grand Answer */}
          {!checkEnvVar("REACT_APP_ENABLE_NEW_ASSISTANT_UI") && <AssistantItem />}

          {/* Search Icon */}
          {!currentTeam?.is_all_team ? <SearchItem /> : null}

          {/* settings and members module */}
          <SettingsAndMembersItem />

          {/* Documentation Icon */}
          <DocumentationItem />

          {/* Notification Component */}
          <NotificationItem />
        </div>
      </section>
    </>
  );
}
