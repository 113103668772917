import React, { useCallback } from "react";

import { AssistantCard } from "./types";
import { AssistantCardItem } from ".";

export type AssistantCardListProps = {
  cards: AssistantCard[];
  onSelect?: (card: AssistantCard) => void;
};

function AssistantCardList({ cards, onSelect: select }: AssistantCardListProps) {
  const selectHandler = useCallback(
    (card: AssistantCard) => {
      if (select !== undefined) {
        select(card);
      }
    },
    [select]
  );

  return (
    <div className="grid sm:grid-cols-2 gap-3 md:gap-4">
      {cards.map((card) => (
        <AssistantCardItem key={card.id} card={card} onClick={() => selectHandler(card)} />
      ))}
    </div>
  );
}

export default AssistantCardList;
