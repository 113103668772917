import React, { ReactNode } from "react";
import clsx from "clsx";

import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

export type CopilotLayoutProps = {
  children: ReactNode;
};

function CopilotLayoutComponent({ children }: CopilotLayoutProps) {
  return (
    <div
      className={clsx(
        "flex",
        "flex-col",
        "rounded-lg",
        "shadow-md",
        "md:max-w-[400px]",
        "w-full",
        "dark:border",
        "dark:border-thunders",
        "assistant_body"
      )}
    >
      {children}
    </div>
  );
}

CopilotLayoutComponent.displayName = "CopilotLayout";
Header.displayName = "CopilotLayout.Header";
Main.displayName = "CopilotLayout.Main";
Footer.displayName = "CopilotLayout.Footer";

const CopilotLayout = Object.assign(CopilotLayoutComponent, { Header, Main, Footer });

export default CopilotLayout;
