import React, { ReactNode, ForwardedRef, forwardRef } from "react";
import clsx from "clsx";

export type MainProps = {
  children: ReactNode;
};

/**
 * Content where messages will be displayed. It is scrollable.
 * Uses ref forwarding to allow the parent component to scroll to the bottom of the content.
 * Content aligment is reversed
 */
const Main = forwardRef(({ children }: MainProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div
      ref={ref}
      className={clsx(
        "flex",
        "flex-col-reverse",
        "grow",
        "overflow-y-auto",
        "pb-4",
        "pt-4",
        "pr-6",
        "pl-6",
        "bg-white",
        "dark:bg-darkjunglegreenss",
        "dark:text-greychateau"
      )}
    >
      {children}
    </div>
  );
});

export default Main;
