import React, { Fragment, useEffect } from "react";
import "./assets/css/global.css";
import {
  Route,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Home from "./modules/home";
import HomeV2 from "./modules/homeV2";
import ResetPassword from "./modules/auth/reset-password/ResetPassword";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SignIn from "./modules/auth/signIn/SignIn";
import ForgotPassword from "./modules/auth/forgot-password/ForgotPassword";
import OnBoarding from "./modules/auth/on-boarding/OnBoarding";
import VerifyEmail from "./modules/auth/verify-email/VerifyEmail";
import withAuthentication from "./shared/helpers/withAuthentication";
import withoutAuthentication from "./shared/helpers/withoutAuthentication";
import SignupThanks from "./modules/auth/signup-thanks/SignupThanks";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffectOnce } from "usehooks-ts";
import { onMessage } from "firebase/messaging";
import { messaging } from "./shared/helpers/firebase";
import "react-datepicker/dist/react-datepicker.css";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import MicrosoftAuth from "./modules/auth/microsoft-auth/MicrosoftAuth";
import SsoSignIn from "./modules/auth/sso-signIn/SsoSignIn";
import SsoAuth from "./modules/auth/sso-auth/SsoAuth";
import ArticleDetailsUnauthorizedPage from "./modules/articles/overview/components/ArticleDetailsUntuhorizedPage";
import RiskMatrixUnauthorizedPage from "./modules/risks/overview/components/RiskMatrixUnauthorizedPage";
import { customTheme, getItemFromLocalStorage } from "./shared/helpers/util";
import { IS_DARK_MODE } from "./shared/helpers/constant";
import { trackPageVisit } from "./shared/analytics";
import GoogleAuthSignin from "./modules/auth/google-auth-signin";
import { Flowbite } from "flowbite-react";
import "rc-slider/assets/index.css";
import * as Sentry from "@sentry/react";
import axios from "axios";
import SignUp from "modules/auth/signup/SignUp";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError: (err, query) => {
      Sentry.withScope((scope) => {
        scope.setContext("query", { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, "0")]);

        if (axios.isAxiosError(err)) {
          try {
            scope.setContext("response", {
              status: err.response?.status,
              data: JSON.stringify(err.response?.data),
            });
          } catch (e) {
            /* empty */
          }
        }

        Sentry.captureException(err);
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      Sentry.withScope((scope) => {
        scope.setContext("mutation", {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });

        if (mutation.options.mutationKey) {
          scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[]);
        }

        if (axios.isAxiosError(err)) {
          try {
            scope.setContext("response", {
              status: err.response?.status,
              data: JSON.stringify(err.response?.data),
            });
          } catch (e) {
            /* empty */
          }
        }

        Sentry.captureException(err);
      });
    },
  }),
});

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_MS_CLIENT_ID}`,
  },
};

const pca = new PublicClientApplication(configuration);

const UnAutheticatedApp = () => <Outlet />;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route path="risks/overview/matrix/public" element={<RiskMatrixUnauthorizedPage />} />
      <Route
        path="articles/overview/:articleId/public"
        element={<ArticleDetailsUnauthorizedPage />}
      />
      <Route path="/workspace/*" element={withAuthentication(HomeV2)} />
      <Route path="/*" element={withAuthentication(Home)} />
      <Route path="on-boarding" element={withAuthentication(OnBoarding)} />
      <Route element={withoutAuthentication(UnAutheticatedApp)}>
        <Route path="sso-signin" element={<SsoSignIn />} />
        <Route path="signin" element={<SignIn />} />
        {/*  restricting access to the app trial only */}
        <Route path="signup" element={<SignUp />} />
        <Route path="signup/thanks" element={<SignupThanks />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:uid/:token" element={<ResetPassword />} />
        <Route path="email/verify/:key" element={<VerifyEmail />} />
        <Route path="google/auth" element={<GoogleAuthSignin />} />
        <Route path="microsoft/auth" element={<MicrosoftAuth />} />
        <Route path="post-auth/redirect" element={<SsoAuth />} />
      </Route>
    </Fragment>
  )
);

const App = () => {
  useEffect(() => {
    if (getItemFromLocalStorage(IS_DARK_MODE)) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, []);

  useEffectOnce(() => {
    trackPageVisit();
  });

  // firebase notification listener
  useEffectOnce(() => {
    if (!messaging) {
      return;
    }
    return onMessage(messaging, (payload) => {
      new Notification(`${payload.notification?.title}`, {
        icon: "/logo.svg",
        ...payload.notification,
        requireInteraction: true,
      });
    });
  });

  return (
    <MsalProvider instance={pca}>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
        <QueryClientProvider client={queryClient}>
          <Flowbite theme={{ theme: customTheme }}>
            <RouterProvider router={router} />
          </Flowbite>
          <ToastContainer
            autoClose={2500}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            closeOnClick
          />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </MsalProvider>
  );
};

export default App;
