import React from "react";
import clsx from "clsx";

import ReactMarkdown from "react-markdown";

import { CopilotChatMessage, TemporaryUserMessage } from "../../types";
import { isTemporaryMessage } from "../../helpers";

export type ChatMessageProps = {
  message: CopilotChatMessage | TemporaryUserMessage;
};

function ChatMessage({ message }: ChatMessageProps) {
  return (
    <li
      data-testid="message-row"
      className={clsx("flex", "flex-row", "w-full", {
        msg_copilot: message.type === "copilot",
        msg_user: message.type === "user",
        "justify-end": message.type === "user",
        "justify-start": message.type === "copilot",
        "opacity-50": isTemporaryMessage(message),
      })}
    >
      <div
        className={clsx("w-full md:w-5/6", "flex", "flex-row", {
          "justify-end": message.type === "user",
          "justify-start": message.type === "copilot",
        })}
      >
        <span data-testid={`${message.type}-message`} className="break-words">
          <ReactMarkdown children={message.text} />
        </span>
      </div>
    </li>
  );
}

export default ChatMessage;
