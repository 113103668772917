import React from "react";

import { useNavigate } from "react-router-dom";

import Icon from "shared/components/icon/Icon";
import { useSettings } from "shared/store/settings";
import checkEnvVar from "shared/helpers/checkEnvVar";

import HeaderItem from "./HeaderItem";

const SettingsAndMembersItem = () => {
  const navigate = useNavigate();

  const setIsSettingsModalOpen = useSettings((state) => state.setIsModalOpen);

  return (
    <>
      <HeaderItem
        testId="headerItem_comp"
        onClick={() => setIsSettingsModalOpen(true)}
        tooltip="Settings & Members"
        className="setting_member_module maxMd:!block"
      >
        <Icon type="setting" fill={true} size="icon-md" />
      </HeaderItem>

      {checkEnvVar("REACT_APP_ENABLE_NEW_SETTING_UI") ? (
        <HeaderItem
          testId="headerItem_comp_2.0"
          onClick={() => {
            navigate("/settings/my-account");
          }}
          tooltip="settings V2"
          className="setting_member_module maxMd:!block"
        >
          <Icon type="new-setting" fill={true} size="icon-md" />
        </HeaderItem>
      ) : null}
    </>
  );
};

export default SettingsAndMembersItem;
