import { SideBarItems } from "shared/components/sidebar/types";

import {
  AGREEMENT_MODULE,
  ARTICLE_MODULE,
  ASSISTANT_MODULE,
  CALENDAR_MODULE,
  COMMUNITY_MODULE,
  DASHBOARD_MODULE,
  DOCUMENT_MODULE,
  NOTE_MODULE,
  POLICY_MODULE,
  QUESTIONNAIRE_MODULE,
  REPORT_MODULE,
  RISK_MODULE,
  TASK_MODULE,
  VENDOR_MODULE,
  VIEW_ACTION,
} from "./ability";
import checkEnvVar from "./checkEnvVar";

export const defaultSideBarItems = (companyId: number): SideBarItems[] => {
  const sidebarItems: SideBarItems[] = [
    {
      label: "Dashboard",
      path: "/dashboard/overview",
      action: VIEW_ACTION,
      subject: DASHBOARD_MODULE,
    },
    {
      label: "Community",
      path: "/community",
      action: VIEW_ACTION,
      subject: COMMUNITY_MODULE,
    },
    {
      label: "Articles",
      items: [
        { label: "Overview", path: "/articles/overview" },
        { label: "Subscriptions", path: "/articles/subscription" },
      ],
      isOpen: false,
      path: "/articles",
      action: VIEW_ACTION,
      subject: ARTICLE_MODULE,
    },
    {
      label: "Agreements",
      items: [
        { label: "Overview", path: "/agreements/overview" },
        { label: "Categories", path: "/agreements/categories" },
        { label: "Agreement Types", path: "/agreements/types" },
        { label: "Suppliers", path: "/agreements/suppliers" },
        { label: "Internal Entities", path: "/agreements/entities" },
      ],
      isOpen: false,
      path: "/agreements",
      action: VIEW_ACTION,
      subject: AGREEMENT_MODULE,
    },
    {
      label: "Policies",
      action: VIEW_ACTION,
      subject: POLICY_MODULE,
      path: "/policies",
      items: [
        { label: "Overview", path: "/policies/overview" },
        { label: "Templates", path: "/policies/templates" },
        { label: "Categories", path: "/policies/categories" },
      ],
    },
    {
      label: "Risks",
      action: VIEW_ACTION,
      subject: RISK_MODULE,
      path: "/risks",
      items: [
        { label: "Overview", path: "/risks/overview" },
        { label: "Register", path: "/risks/register" },
        { label: "Incidents", path: "/risks/incidents" },
        { label: "Mitigations", path: "/risks/mitigations" },
        { label: "Yearly Planner", path: "/risks/yearly-planner" },
        { label: "Library", path: "/risks/library" },
      ],
    },
    {
      label: "Calendar",
      path: "/calendar",
      action: VIEW_ACTION,
      subject: CALENDAR_MODULE,
    },
    {
      label: "Tasks",
      action: VIEW_ACTION,
      subject: TASK_MODULE,
      path: "/tasks",
      items: [
        { label: "Overview", path: "/tasks/overview" },
        { label: "Statuses", path: "/tasks/statuses" },
      ],
    },
    {
      label: "Documents",
      action: VIEW_ACTION,
      subject: DOCUMENT_MODULE,
      path: "/documents",
    },
    {
      label: "Notes",
      action: VIEW_ACTION,
      subject: NOTE_MODULE,
      path: "/notes",
    },
    {
      label: "Vendors",
      action: VIEW_ACTION,
      subject: VENDOR_MODULE,
      path: `/workspace/${companyId}/vendors`,
      isBeta: true,
    },
    {
      label: "Dashboards & Reports",
      action: VIEW_ACTION,
      subject: REPORT_MODULE,
      path: `/workspace/${companyId}/reports`,
    },
    {
      label: "Questionnaires",
      action: VIEW_ACTION,
      subject: QUESTIONNAIRE_MODULE,
      path: `/workspace/${companyId}/questionnaire`,
      isBeta: true,
      visibleToProd: false,
    },
    {
      label: "Risks Management",
      action: VIEW_ACTION,
      subject: RISK_MODULE,
      path: `/workspace/${companyId}/risks/registers`,
      isBeta: true,
      visibleToProd: false,
      items: [{ label: "Risk Registers", path: `/workspace/${companyId}/risks/registers` }],
    },
  ];

  if (checkEnvVar("REACT_APP_ENABLE_NEW_ASSISTANT_UI")) {
    sidebarItems.splice(4, 0, {
      label: "Assistant",
      action: VIEW_ACTION,
      subject: ASSISTANT_MODULE,
      path: `/workspace/${companyId}/assistant/document-assistant`,
      items: [
        {
          label: "Talk to your documents",
          path: `/workspace/${companyId}/assistant/document-assistant`,
        },
        { label: "Talk to Eurlex", path: `/workspace/${companyId}/assistant/eurlex` },
        { label: "Regulatory expert", path: `/workspace/${companyId}/assistant/regulatory-expert` },
      ],
    });
  }

  return sidebarItems;
};
